export async function postRFP(values) {
    // Construct FormData
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("job_title", values.jobTitle);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("country", values.location);
    formData.append("company_name", values.companyName);
    formData.append("industry", values.industry);
    // Add attachments if they exist
    if (values.file1) formData.append("attachment1", values.file1, values.file1.name);
    if (values.file2) formData.append("attachment2", values.file2, values.file2.name);
    if (values.file3) formData.append("attachment3", values.file3, values.file3.name);
    formData.append("comments", values.comments);
    // For booleans, some APIs may want strings "true"/"false" 
    // But in your curl example you used "true" as a string.
    formData.append("terms_conditions", values.agreeToTerms ? "true" : "false");

    const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow",
    };

    try {
        const response = await fetch(
            "https://python.eyouthlearning.com/eyouth/proposalrequest/",
            requestOptions
        );

        // Handle unsuccessful responses
        if (!response.ok) {
            throw new Error(`Server responded with ${response.status}`);
        }

        // Assuming API returns JSON
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error posting RFP:", error);
        throw error;
    }
}
