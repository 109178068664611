import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { postRFP } from '../../service/rfpPostApi';
import bottomYellow from '../../assets/bottomOne.png';
import eyouthWhite from '../../assets/EYouth Logo W.webp';
import topYellow from '../../assets/topone.png';
import styles from './RFP.module.css';
import { useTranslation } from 'react-i18next';

const ALLOWED_FILE_TYPES = ['.doc', '.pdf', '.ppt', '.txt', '.xlsx', '.zip'];

function isAllowedFile(file) {
    if (!file) return true; // If no file, skip validation
    const fileName = file.name.toLowerCase();
    return ALLOWED_FILE_TYPES.some(ext => fileName.endsWith(ext));
}

export default function RFP() {
    const [apiError, setApiError] = useState('');
    const [apiSuccess, setApiSuccess] = useState('');
    const { i18n } = useTranslation();

    useEffect(() => {
        // Save original direction/lang
        const originalDir = document.dir;
        const originalLang = document.documentElement.lang;

        // Force LTR + lang="en" on this page
        document.dir = 'ltr';
        document.documentElement.lang = 'en';

        // Cleanup: revert direction/lang when leaving RFP
        return () => {
            document.dir = originalDir;
            document.documentElement.lang = originalLang;
        };
    }, [i18n.language]);

    const initialValues = {
        // Step 1 fields
        title: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        phone: '',
        location: '',
        // Step 2 fields
        companyName: '',
        industry: '',
        // Step 3 fields
        file1: null,
        file2: null,
        file3: null,
        comments: '',
        agreeToTerms: false,
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        jobTitle: Yup.string(),
        email: Yup.string().email('Invalid email format').required('Required'),
        phone: Yup.string()
            .matches(/^[0-9]{7,15}$/, 'Phone must be 7-15 digits.')
            .required('Required'),
        location: Yup.string().required('Required'),

        companyName: Yup.string(),
        industry: Yup.string().required('Required'),

        comments: Yup.string().required('Required'),
        agreeToTerms: Yup.boolean()
            .oneOf([true], 'You must agree to the terms')
            .required('Required'),

        file1: Yup.mixed().test(
            'fileType',
            `Unsupported file type. Allowed: ${ALLOWED_FILE_TYPES.join(', ')}`,
            (value) => isAllowedFile(value)
        ),
        file2: Yup.mixed().test(
            'fileType',
            `Unsupported file type. Allowed: ${ALLOWED_FILE_TYPES.join(', ')}`,
            (value) => isAllowedFile(value)
        ),
        file3: Yup.mixed().test(
            'fileType',
            `Unsupported file type. Allowed: ${ALLOWED_FILE_TYPES.join(', ')}`,
            (value) => isAllowedFile(value)
        ),
    });

    const handleSubmit = async (values, { resetForm }) => {
        try {
            setApiError('');
            setApiSuccess('');
            const response = await postRFP(values);

            // Show success message
            setApiSuccess('Form submitted successfully!');
            resetForm();
        } catch (error) {
            console.error('Submission error:', error);
            setApiError('There was an error submitting the form. Please try again.');
        }
    };

    return (
        <div className={styles.rfpContainer}>
            <img src={topYellow} alt="topYellow" className={styles.topYellow} />
            <div className={styles.logoContainer}>
                <img src={eyouthWhite} alt="EYouth" />
            </div>
            <div className={styles.headerContainer}>
                <h2>Submit RFP</h2>
                <h3>Request for proposal for services</h3>
                <h4>How can we help your business?</h4>
                <span>
                    Thank you for your interest in EYouth services. Please take a few
                    moments to complete this form. Documents can be uploaded if needed to
                    clarify your request.
                </span>
                <h5>
                    Learn more about
                    <Link to='/about-eyouth' style={{ marginInline: '0.4rem' }}>
                        EYouth and its services.
                    </Link>
                </h5>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, errors, touched }) => (
                    <Form>
                        <div className={styles.formContainer}>
                            {/* Step 1: Your Details */}
                            <div>
                                <div className={styles.step}>
                                    <div className={styles.circle}>1</div>
                                    <h1>Your details</h1>
                                    <div className={styles.line}></div>
                                </div>

                                <div className={styles.formContent}>
                                    <h3>Fields marked with an asterisk (*) are required.</h3>
                                    <div className={styles.formGroup}>
                                        <label htmlFor='title'>Title *</label>
                                        <Field as='select' name='title' id='title'>
                                            <option value=''>Select Title</option>
                                            <option value='mr'>Mr</option>
                                            <option value='ms'>Ms</option>
                                            <option value='mrs'>Mrs</option>
                                            <option value='dr'>Dr</option>
                                        </Field>
                                        <ErrorMessage
                                            name='title'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='firstName'>First Name *</label>
                                        <Field type='text' name='firstName' id='firstName' />
                                        <ErrorMessage
                                            name='firstName'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='lastName'>Last Name *</label>
                                        <Field type='text' name='lastName' id='lastName' />
                                        <ErrorMessage
                                            name='lastName'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='jobTitle'>Position / Job title</label>
                                        <Field type='text' name='jobTitle' id='jobTitle' />
                                        <ErrorMessage
                                            name='jobTitle'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='email'>Email address *</label>
                                        <Field type='email' name='email' id='email' />
                                        <ErrorMessage
                                            name='email'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='phone'>Phone number</label>
                                        <Field type='text' name='phone' id='phone' />
                                        <ErrorMessage
                                            name='phone'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='location'>Country / location *</label>
                                        <Field type='text' name='location' id='location' />
                                        <ErrorMessage
                                            name='location'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Step 2: Company Details */}
                            <div>
                                <div className={styles.step}>
                                    <div className={styles.circle}>2</div>
                                    <h1>Company details</h1>
                                    <div className={styles.line}></div>
                                </div>
                                <div className={styles.formContent}>
                                    <div className={styles.formGroup}>
                                        <label htmlFor='companyName'>Company / Organization name</label>
                                        <Field type='text' name='companyName' id='companyName' />
                                        <ErrorMessage
                                            name='companyName'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor='industry'>Industry *</label>
                                        <Field as='select' name='industry' id='industry'>
                                            <option value='' disabled>
                                                Select Industry
                                            </option>
                                            <option value='tech'>Technology</option>
                                            <option value='finance'>Finance</option>
                                            <option value='health'>Healthcare</option>
                                            <option value='education'>Education</option>
                                            <option value='other'>Others</option>
                                        </Field>
                                        <ErrorMessage
                                            name='industry'
                                            component='div'
                                            className={styles.error}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Step 3: Additional Information */}
                            <div>
                                <div className={styles.step}>
                                    <div className={styles.circle}>3</div>
                                    <h1>Additional information</h1>
                                    <div className={styles.line}></div>
                                </div>
                                <div className={styles.formContent}>
                                    <h3>
                                        Please note that the total size of your attachment(s) must not
                                        exceed 10 Mb. Appropriate attachment types are ".doc", ".pdf",
                                        ".ppt", ".txt", ".xlsx", and ".zip".
                                    </h3>
                                    <div className={styles.formGroup}>
                                        <label htmlFor="file1">Attachment</label>
                                        <input
                                            type="file"
                                            id="file1"
                                            onChange={(e) => setFieldValue('file1', e.target.files[0])}
                                        />
                                        {/* Show validation error if file type is invalid */}
                                        {errors.file1 && touched.file1 && (
                                            <div className={styles.error}>{errors.file1}</div>
                                        )}
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor="file2">Attachment</label>
                                        <input
                                            type="file"
                                            id="file2"
                                            onChange={(e) => setFieldValue('file2', e.target.files[0])}
                                        />
                                        {errors.file2 && touched.file2 && (
                                            <div className={styles.error}>{errors.file2}</div>
                                        )}
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor="file3">Attachment</label>
                                        <input
                                            type="file"
                                            id="file3"
                                            onChange={(e) => setFieldValue('file3', e.target.files[0])}
                                        />
                                        {errors.file3 && touched.file3 && (
                                            <div className={styles.error}>{errors.file3}</div>
                                        )}
                                    </div>

                                    <div className={styles.formGroup}>
                                        <label htmlFor="comments">
                                            Comments and/or instructions *
                                        </label>
                                        <Field as="textarea" name="comments" id="comments" rows="4" />
                                        <ErrorMessage
                                            name="comments"
                                            component="div"
                                            className={styles.error}
                                        />
                                    </div>

                                    <div className={styles.formGroupCheckbox}>
                                        <label htmlFor="agreeToTerms">
                                            <Field
                                                type="checkbox"
                                                name="agreeToTerms"
                                                id="agreeToTerms"
                                            />{' '}
                                            I have read and agree to the Privacy Notice and Terms of Use.
                                        </label>
                                        <ErrorMessage
                                            name="agreeToTerms"
                                            component="div"
                                            className={styles.error}
                                        />
                                    </div>
                                </div>

                                {/* Network error or success message */}
                                {apiError && (
                                    <div className={styles.error} style={{ marginTop: '1rem' }}>
                                        {apiError}
                                    </div>
                                )}
                                {apiSuccess && (
                                    <div
                                        style={{
                                            marginTop: '1rem',
                                            color: 'green',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {apiSuccess}
                                    </div>
                                )}

                                <button type="submit" className={styles.submitBtn}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <img src={bottomYellow} alt="bottomYellow" className={styles.bottomYellow} />
        </div>
    );
}